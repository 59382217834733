.mat-form-field-infix:has(input[type="date"]) {
  padding: .75em 1em .483em .5em !important;
  font-size: 14px !important;
}
// input hover color
.mat-form-field-appearance-outline .mat-form-field-flex {
  &:hover {
    .mat-form-field-outline .mat-form-field-outline-start, .mat-form-field-outline .mat-form-field-outline-end, .mat-form-field-outline .mat-form-field-outline-gap {
      background-color: #f2f2f2 !important;
    }
  }
}
// input focused color
.mat-focused .mat-form-field-flex {
  .mat-form-field-outline .mat-form-field-outline-start, .mat-form-field-outline .mat-form-field-outline-end, .mat-form-field-outline .mat-form-field-outline-gap {
    background-color: #f2f2f2 !important;
  }
}
// input default border color
.mat-form-field-appearance-outline .mat-form-field-outline, .mat-focused .mat-form-field-outline {
  color: #575757;
}
// input default border width
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
}
// disable input autofill background
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 !important;
  pointer-events: auto;
}